@import "../../../styles/base/abstracts";

.footnotes {
  @include baseline-text;
  margin-top: $spacing-xxl;
  padding-top: $spacing-xxl;
  border-top: 1px solid $c-border;
  ol {
    @include ol;
  }

  li {
    scroll-margin-top: calc(#{$header-shift * 2});
  }

  .hbs-landing-page-body & {
    margin-bottom: $spacing-xxl;
  }
}

.footnote-marker {
  display: inline;
  position: relative;
  padding-right: 22px;
  scroll-margin-top: calc(#{$header-shift * 2});

  .footnote-marker__link {
    @include unlink;
    @include size(1.7em);
    @include absolute(0 0.1em x x);
    align-items: center;
    background-color: $c-spot;
    border-radius: 50%;
    color: $c-bg;
    display: inline-flex;
    font-weight: $fw-semibold;
    font-family: $ff-sans;
    font-size: 10px;
    justify-content: center;
    letter-spacing: 0;
    padding-left: 0.04em;

    &:hover,
    &:focus {
      color: $c-bg;
      background-color: $c-highlight;
    }
  }
}

.footnote__back__text {
  @include hide;
}

.footnote__back {
  @include unlink;
  @include subtle-link;
  color: $c-spot;
}
